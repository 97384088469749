import { ErrorOption, FieldPath } from 'react-hook-form';
import * as z from 'zod';

import { Project } from '@/types/Project.ts';

const timeSheetEntryFormSchema = z
  .object({
    category: z.string().nullable().optional(),
    description: z
      .string()
      .min(1, { message: 'Description is required' })
      .refine(
        (val) => val.length <= 300,
        (val) => ({ message: `${val.length - 300} characters over the limit` })
      ),
    hours: z.coerce
      .number({
        invalid_type_error: 'Hours must be a number',
        required_error: 'Hours are required',
      })
      .positive({ message: 'Value must be positive' })
      .multipleOf(0.1)
      .max(24, { message: 'Hours must be less than 24 hours' }),
    project: z.string(),
    task: z.string().nullable(),
  })
  .superRefine((val, ctx) => {
    if (val.task === null) {
      ctx.addIssue({
        code: 'custom',
        message: 'Task is required',
        path: ['task'],
      });
    }
  });

export const timeSheetEntryAddSchema = z.intersection(
  timeSheetEntryFormSchema,
  z.object({
    dates: z.date().array(),
  })
);

export const timeSheetEntryEditSchema = z.intersection(
  timeSheetEntryFormSchema,
  z.object({
    date: z.date(),
  })
);

export type TimeSheetEntryAddValues = z.infer<typeof timeSheetEntryAddSchema>;
export type TimeSheetEntryEditValues = z.infer<typeof timeSheetEntryEditSchema>;
export type TimeSheetEntryFormValues = z.infer<typeof timeSheetEntryFormSchema>;

export function setCategoryIsValid(
  projects: Project[],
  data: TimeSheetEntryFormValues,
  clearErrors: (
    name?:
      | FieldPath<TimeSheetEntryFormValues>
      | FieldPath<TimeSheetEntryFormValues>[]
      | `root.${string}`
      | 'root'
  ) => void,
  setError: (
    name: FieldPath<TimeSheetEntryFormValues> | `root.${string}` | 'root',
    error: ErrorOption,
    options?: { shouldFocus: boolean }
  ) => void
): boolean {
  clearErrors('category');
  const project = projects.find((p) => p.stintId === data.project);
  const categoryIsValid = !project?.categoryIsRequired || !!data.category;

  if (!categoryIsValid) {
    setError('category', {
      message: `Category is required for project ${project.name}.`,
      type: 'manual',
    });
  }

  return categoryIsValid;
}
