import { zodResolver } from '@hookform/resolvers/zod';
import { CalendarIcon } from '@radix-ui/react-icons';
import { useAtomValue } from 'jotai';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { TimeSheetEntryForm } from '@/components/TimeSheetEntryForm.tsx';
import { requestedPayPeriodAtom, totalHoursPerDayAtom } from '@/config/jotai';
import { cn } from '@/lib/utils';
import { Project } from '@/types/Project';
import {
  setCategoryIsValid,
  timeSheetEntryEditSchema,
  TimeSheetEntryEditValues,
} from '@/types/TimeSheetEntryFormValues';

import { Button } from './ui/button';
import { Calendar } from './ui/calendar';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from './ui/form';
import { Popover, PopoverContent, PopoverTrigger } from './ui/popover';

type TimeSheetEntryEditFormProps = {
  defaultValues: Partial<TimeSheetEntryEditValues>;
  projects: Project[];
  handleSubmit: SubmitHandler<TimeSheetEntryEditValues>;
  submitPending: boolean;
};

export const TimeSheetEntryEditForm = ({
  defaultValues,
  projects,
  handleSubmit,
  submitPending,
}: TimeSheetEntryEditFormProps) => {
  const [open, setOpen] = useState(false);
  const requestedPayPeriod = useAtomValue(requestedPayPeriodAtom);
  const totalHoursPerDay = useAtomValue(totalHoursPerDayAtom);

  const form = useForm<TimeSheetEntryEditValues>({
    defaultValues,
    mode: 'onChange',
    resolver: zodResolver(timeSheetEntryEditSchema),
  });

  const willExceed24Hours = (hours: number, date: Date) => {
    const currentEntryHours = defaultValues.hours ?? 0;
    const currentHoursForDay = totalHoursPerDay.get(date.toDateString()) ?? 0;
    return currentHoursForDay - currentEntryHours + hours > 24;
  };

  // Submit the form when the user presses Ctrl + Enter
  const handleOnKeyDownCapture = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.ctrlKey && event.key === 'Enter') {
      event.preventDefault();
      form.handleSubmit(submitForm)();
    }
  };

  const submitForm = (data: TimeSheetEntryEditValues) => {
    const categoryIsValid = setCategoryIsValid(projects, data, form.clearErrors, form.setError);
    if (!categoryIsValid) {
      return;
    }

    form.clearErrors('hours');
    const date = data.date.toLocaleDateString();
    if (willExceed24Hours(data.hours, data.date)) {
      form.setError('hours', {
        message: `The total number of hours for ${date} will exceed 24 hours`,
        type: 'manual',
      });
    } else {
      handleSubmit(data);
    }
  };

  function getCalendarDisplayValue(date: Date): string {
    return date.toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'short',
    });
  }

  const calendarFormField = (
    <FormField
      control={form.control}
      name="date"
      render={({ field }) => (
        <FormItem className="flex flex-col">
          <FormLabel>Date</FormLabel>
          <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant="outline"
                  className={cn(
                    'pl-3 text-left font-normal',
                    !field.value && 'text-muted-foreground'
                  )}
                  disabled={submitPending}
                >
                  <span>{getCalendarDisplayValue(field.value)}</span>
                  <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
              <Calendar
                mode="single"
                selected={field.value}
                onSelect={field.onChange}
                onDayClick={() => setOpen(false)}
                disabled={(date) =>
                  date > requestedPayPeriod.endDate || date < requestedPayPeriod.beginDate
                }
                defaultMonth={requestedPayPeriod.beginDate}
                required
              />
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );

  return (
    <Form {...form}>
      <form
        id="time-log-modal-submit"
        onSubmit={form.handleSubmit(submitForm)}
        className="flex w-full flex-col gap-3"
        onKeyDownCapture={handleOnKeyDownCapture}
      >
        <TimeSheetEntryForm
          calendarFormField={calendarFormField}
          defaultValues={defaultValues}
          projects={projects}
          submitPending={submitPending}
        />
      </form>
    </Form>
  );
};
