import { ThemeProvider } from '@/components/theme-provider';

import { Layout } from './components/layout/Layout';
import { Toaster } from './components/ui/toaster';
import { TimeTracker } from './pages/TimeTracker/TimeTracker';

function App() {
  return (
    <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
      <Layout>
        <TimeTracker />
        <Toaster />
      </Layout>
    </ThemeProvider>
  );
}

export default App;
