import { PinBottomIcon, PinTopIcon } from '@radix-ui/react-icons';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import { getTimeSheetPayload } from '@/api/getTimeSheetPayload';
import { EgSpinner } from '@/components/EgSpinner';
import { TimeSheetDayCard } from '@/components/TimeSheetDayCard.tsx';
import { TimeSheetEntryAddButton } from '@/components/TimeSheetEntryAddButton';
import { Button } from '@/components/ui/button.tsx';
import { Label } from '@/components/ui/label';
import { Switch } from '@/components/ui/switch';
import {
  globalDayCardStateAtom,
  hideEmptyDaysAtom,
  isDataLoadingAtom,
  payPeriodAtom,
  payPeriodHasTimeEntriesAtom,
  projectIdColorsAtom,
  requestedPayPeriodAtom,
  someTimeEntriesAreOpenAtom,
  someTimeEntriesAreSubmittedAtom,
  totalHoursPerDayAtom,
  userIsW2Atom,
} from '@/config/jotai';
import { QueryKeys } from '@/lib/utils';
import { ProjectIdColor } from '@/types/ProjectIdColor';
import { TimeSheetEntry } from '@/types/TimeSheetEntry.ts';
import { TimeSheetPayload } from '@/types/TimeSheetPayload';
import { getDatesInRange } from '@/utility/dateUtils';

const barColors: string[] = [
  '#4572A7', // blue
  '#AA4643', // red,
  '#89A54E', // green
  '#DB843D', // orange
  '#80699B', // purple
  '#92A8CD', // light blue
  '#3D96AE', // teal blue
  '#B5CA92', // light green
  '#A47D7C', // brown
];

export const TimeSheet = () => {
  const requestedPayPeriod = useAtomValue(requestedPayPeriodAtom);
  const isDataLoading = useAtomValue(isDataLoadingAtom);
  const setGlobalDayCardState = useSetAtom(globalDayCardStateAtom);
  const setPayPeriod = useSetAtom(payPeriodAtom);
  const setPayPeriodHasTimeEntries = useSetAtom(payPeriodHasTimeEntriesAtom);
  const setSomeTimeEntriesAreOpen = useSetAtom(someTimeEntriesAreOpenAtom);
  const setSomeTimeEntriesAreSubmitted = useSetAtom(someTimeEntriesAreSubmittedAtom);
  const setTotalHoursPerDay = useSetAtom(totalHoursPerDayAtom);
  const setUserIsW2 = useSetAtom(userIsW2Atom);
  const [hideEmptyDays, setHideEmptyDays] = useAtom(hideEmptyDaysAtom);
  const setProjectIdColors = useSetAtom(projectIdColorsAtom);

  const { data, isLoading }: UseQueryResult<TimeSheetPayload> = useQuery({
    queryFn: () => getTimeSheetPayload({ dateInPayPeriod: requestedPayPeriod.beginDate }),
    queryKey: [QueryKeys.TimeSheetPayload, requestedPayPeriod],
  });

  if (data) {
    setPayPeriod(data.payPeriod);
    setPayPeriodHasTimeEntries(data.timeSheet.length > 0);
    setSomeTimeEntriesAreOpen(data.timeSheet.some((entry) => entry.statusCode === 'Open'));
    setSomeTimeEntriesAreSubmitted(
      data.timeSheet.some((entry) => entry.statusCode === 'Submitted')
    );
    setUserIsW2(data.userContext.statusCode === 'W2S');
  }

  const payPeriodDates = data
    ? getDatesInRange(new Date(data.payPeriod.beginDate), new Date(data.payPeriod.endDate)).map(
        (date) => date.toDateString()
      )
    : [];

  const projects =
    data?.submittableProjects.map((project) => {
      project.tasks = project.tasks.map((task) => {
        // Allow the task name portion that comes after the colon to wrap.
        return { ...task, displayName: task.displayName.replace(':', ':\u200B') };
      });
      return project;
    }) ?? [];

  const holidays = data?.payPeriod?.holidays ?? [];
  const timeSheetEntries = data?.timeSheet ?? [];

  // Get grouped time entries
  const entriesByDate = timeSheetEntries.reduce((result, entry) => {
    const timelessDate = new Date(entry.entryDate).toDateString();
    let entriesForDate = result.get(timelessDate);

    if (!entriesForDate) {
      entriesForDate = [];
      result.set(timelessDate, entriesForDate);
    }

    entriesForDate.push(entry);

    return result;
  }, new Map<string, TimeSheetEntry[]>());

  // Grouping and adding up hours for each day
  const totalHoursPerDay = new Map<string, number>();

  for (const [key, value] of entriesByDate) {
    const hoursForDate = value.reduce((acc, entry) => acc + entry.hours, 0);
    totalHoursPerDay.set(key, hoursForDate);
  }

  setTotalHoursPerDay(totalHoursPerDay);

  // Build project ID and color map
  const projectIds = [...new Set(timeSheetEntries.map((entry) => entry.projectId))];

  const projectIdColors: ProjectIdColor[] = [];

  for (const [index, projectId] of projectIds.entries()) {
    if (projectId === undefined) continue;

    const color = barColors[index % (barColors.length - 1)];

    const entry: ProjectIdColor = {
      color,
      projectId,
    };

    projectIdColors.push(entry);
  }

  setProjectIdColors(projectIdColors);

  if (isLoading || isDataLoading) return <EgSpinner />;

  return (
    <div className="container mb-12 flex-1 sm:mb-0">
      <div className="sm:top-30 container pointer-events-none fixed z-10 flex justify-end sm:mt-2 xsonly:bottom-16">
        <TimeSheetEntryAddButton
          projects={projects}
          className="pointer-events-auto ml-auto mr-6 h-11 w-11 rounded-full sm:mr-2"
        />
      </div>
      <div className="mt-4 hidden sm:flex">
        <div className="ml-2 flex items-center space-x-2">
          <Switch id="hide-empty-days" checked={hideEmptyDays} onCheckedChange={setHideEmptyDays} />
          <Label htmlFor="hide-empty-days" className="cursor-pointer">
            Hide Empty Days
          </Label>
        </div>
        <div className="ml-auto flex pr-16">
          <Button
            className="-mt-1.5 mr-0.5 h-10 w-12 rounded-r-none p-0"
            onClick={() => setGlobalDayCardState('open')}
          >
            <PinBottomIcon className="h-6 w-6" />
          </Button>
          <Button
            className="-mt-1.5 h-10 w-12 rounded-l-none p-0"
            onClick={() => setGlobalDayCardState('closed')}
          >
            <PinTopIcon className="h-6 w-6" />
          </Button>
        </div>
      </div>
      <div className="sm:mt-3">
        {payPeriodDates.map((key) => (
          <TimeSheetDayCard
            key={key}
            entryDate={new Date(key)}
            isHoliday={holidays.some((holiday) => new Date(holiday.date).toDateString() === key)}
            projects={projects}
            timeSheetEntries={entriesByDate.get(key) ?? []}
          />
        ))}
      </div>
    </div>
  );
};
