import { queryApi } from '@/utility/queryApi';

export type DeleteTimeSheetEntryProps = {
  timeSheetEntryId?: string;
};

export const deleteTimeSheetEntry = ({ timeSheetEntryId }: DeleteTimeSheetEntryProps) =>
  queryApi<undefined>({
    method: 'DELETE',
    path: `/api/time-sheet/entries/${timeSheetEntryId}`,
  });
