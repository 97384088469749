import { ClockIcon } from '@heroicons/react/24/outline';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ReactNode, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';

import { deleteTimeSheetEntry, DeleteTimeSheetEntryProps } from '@/api/deleteTimeSheetEntry';
import { editTimeSheetEntry, EditTimeSheetEntryProps } from '@/api/editTimeSheetEntry';
import { TimeSheetEntryEditForm } from '@/components/TimeSheetEntryEditForm.tsx';
import { Separator } from '@/components/ui/separator.tsx';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { cn, QueryKeys } from '@/lib/utils.ts';
import { Project } from '@/types/Project';
import { TimeSheetEntry } from '@/types/TimeSheetEntry';
import { TimeSheetEntryEditValues } from '@/types/TimeSheetEntryFormValues';

import { Icons } from './icons';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from './ui/alert-dialog';
import { Button } from './ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from './ui/card';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooterWithDelete,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from './ui/dialog';
import { useToast } from './ui/use-toast';

const HeaderText = ({
  timeSheetEntry,
  cardDescription,
  marker,
}: {
  timeSheetEntry: TimeSheetEntry;
  cardDescription: string | undefined;
  marker?: ReactNode;
}) => {
  return (
    <>
      <div className="grid grid-cols-[4fr_1fr] items-start gap-4 text-left">
        <CardTitle>
          {timeSheetEntry.project?.displayName}
          {marker}
        </CardTitle>
        <span className="ml-auto inline-flex font-bold text-card-accent">
          <ClockIcon className="mx-1 h-4 w-4 self-center" />
          <span>{timeSheetEntry.hours}</span>
        </span>
      </div>
      <CardDescription className="text-left">{cardDescription}</CardDescription>
    </>
  );
};

type Props = {
  timeSheetEntry: TimeSheetEntry;
  projects: Project[];
};

export const TimeSheetEntryCard = ({
  timeSheetEntry,
  projects,
  className,
}: Props & React.HTMLAttributes<HTMLDivElement>) => {
  const [showEditTimeSheetEntryDialog, setShowEditTimeSheetEntryDialog] = useState(false);
  const [showDeleteTimeSheetEntryDialog, setShowDeleteTimeSheetEntryDialog] = useState(false);

  const { toast } = useToast();

  const queryClient = useQueryClient();

  const { mutate: editTimeSheetEntryMutate, isPending: editTimeSheetEntryPending } = useMutation({
    mutationFn: ({
      categoryId,
      description,
      entryDate,
      hours,
      isNoVendorCharge,
      statusCode,
      stintId,
      taskId,
      timeSheetEntryId,
    }: EditTimeSheetEntryProps) =>
      editTimeSheetEntry({
        categoryId,
        description,
        entryDate,
        hours,
        isNoVendorCharge,
        statusCode,
        stintId,
        taskId,
        timeSheetEntryId,
      }),
    onError: (error) => {
      toast({
        description: error.message,
        title: error.name,
        variant: 'destructive',
      });
    },
    onSuccess: () => {
      toast({
        description: `The changes were submitted successfully.`,
        title: 'Edit Time Sheet Entry',
      });
      // Refresh the time sheet
      queryClient.invalidateQueries({ queryKey: [QueryKeys.TimeSheetPayload] });
      setShowEditTimeSheetEntryDialog(false);
    },
  });

  const { mutate: deleteTimeSheetEntryMutate, isPending: deleteTimeSheetEntryPending } =
    useMutation({
      mutationFn: ({ timeSheetEntryId }: DeleteTimeSheetEntryProps) =>
        deleteTimeSheetEntry({
          timeSheetEntryId,
        }),
      onError: (error) => {
        toast({
          description: error.message,
          title: error.name,
          variant: 'destructive',
        });
      },
      onSuccess: () => {
        toast({
          description: `The time entry has been deleted.`,
          title: 'Delete Time Entry',
        });

        // Refresh the time sheet
        queryClient.invalidateQueries({ queryKey: [QueryKeys.TimeSheetPayload] });
        setShowEditTimeSheetEntryDialog(false);
        setShowDeleteTimeSheetEntryDialog(false);
      },
    });

  const handleSubmit: SubmitHandler<TimeSheetEntryEditValues> = (data) => {
    editTimeSheetEntryMutate({
      categoryId: data.category,
      description: data.description,
      entryDate: data.date,
      hours: data.hours.toFixed(1),
      isNoVendorCharge: timeSheetEntry.isNoVendorCharge,
      statusCode: timeSheetEntry.statusCode,
      stintId: data.project,
      taskId: data.task ?? '',
      timeSheetEntryId: timeSheetEntry.id,
    });
  };

  const defaultValues: TimeSheetEntryEditValues = {
    category: timeSheetEntry.categoryId,
    date: new Date(timeSheetEntry.entryDate),
    description: timeSheetEntry.description,
    hours: timeSheetEntry.hours,
    project: timeSheetEntry.stintId,
    task: timeSheetEntry.taskId,
  };

  const cardDescription = timeSheetEntry.projectCategory
    ? `${timeSheetEntry.projectTask?.displayName}, ${timeSheetEntry.projectCategory.displayName}`
    : timeSheetEntry.projectTask?.displayName;

  const canEditTimeEntry =
    timeSheetEntry.statusCode === 'Open' || timeSheetEntry.statusCode === 'Submitted';

  return (
    <Dialog
      open={canEditTimeEntry && showEditTimeSheetEntryDialog}
      onOpenChange={setShowEditTimeSheetEntryDialog}
    >
      <DialogTrigger asChild>
        <Card
          className={cn(
            canEditTimeEntry &&
              'cursor-pointer transition-all hover:bg-accent hover:text-accent-foreground',
            className
          )}
        >
          <CardHeader>
            {timeSheetEntry.projectCategory &&
            timeSheetEntry.projectCategory.displayName ===
              import.meta.env.VITE_CALENDAR_CATEGORY ? (
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <HeaderText
                      timeSheetEntry={timeSheetEntry}
                      cardDescription={cardDescription}
                      marker={<span className="ml-2  font-black text-yellow-500">!</span>}
                    />
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>{`Change the category "${timeSheetEntry.projectCategory.displayName}" before submitting the time sheet.`}</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            ) : (
              <HeaderText timeSheetEntry={timeSheetEntry} cardDescription={cardDescription} />
            )}
          </CardHeader>
          <Separator className="mx-auto w-[96%]" />
          <CardContent>{timeSheetEntry.description}</CardContent>
        </Card>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit Time Sheet Entry</DialogTitle>
          <DialogDescription>Make changes to the current time sheet entry</DialogDescription>
        </DialogHeader>
        <TimeSheetEntryEditForm
          defaultValues={defaultValues}
          projects={projects}
          handleSubmit={handleSubmit}
          submitPending={editTimeSheetEntryPending}
        />
        <DialogFooterWithDelete>
          <AlertDialog
            open={showDeleteTimeSheetEntryDialog}
            onOpenChange={setShowDeleteTimeSheetEntryDialog}
          >
            <AlertDialogTrigger asChild>
              <Button variant="destructive" type="button" disabled={editTimeSheetEntryPending}>
                Delete
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                <AlertDialogDescription>
                  This action cannot be undone. This will permanently delete your time sheet entry
                  and remove your data from our servers.
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel disabled={deleteTimeSheetEntryPending}>Cancel</AlertDialogCancel>
                <AlertDialogAction
                  variant="destructive"
                  disabled={deleteTimeSheetEntryPending}
                  type="submit"
                  onClick={() =>
                    deleteTimeSheetEntryMutate({ timeSheetEntryId: timeSheetEntry.id })
                  }
                >
                  {deleteTimeSheetEntryPending ? (
                    <Icons.spinner className="mr-2 h-4 w-full animate-spin" />
                  ) : (
                    ''
                  )}{' '}
                  Delete
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
          <div className="flex flex-col-reverse gap-3 sm:flex-row sm:justify-between">
            <Button
              variant="outline"
              disabled={editTimeSheetEntryPending}
              onClick={() => setShowEditTimeSheetEntryDialog(false)}
            >
              Cancel
            </Button>
            <Button form="time-log-modal-submit" type="submit" disabled={editTimeSheetEntryPending}>
              {editTimeSheetEntryPending ? (
                <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
              ) : (
                ''
              )}{' '}
              Submit
            </Button>
          </div>
        </DialogFooterWithDelete>
      </DialogContent>
    </Dialog>
  );
};
