import { TimeSheetEntry } from '@/types/TimeSheetEntry';
import { queryApi } from '@/utility/queryApi';

export type AddTimeSheetEntriesv2Props = {
  timesheetEntries: TimeSheetEntry[];
};

export const addTimeSheetEntriesv2 = ({ timesheetEntries }: AddTimeSheetEntriesv2Props) =>
  queryApi<TimeSheetEntry[]>({
    body: JSON.stringify(timesheetEntries),
    method: 'POST',
    path: '/api/time-sheet/entriesv2',
  });
