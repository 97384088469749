import { BetaBar } from '@/components/BetaBar';
import { PayPeriodSelector } from '@/components/PayPeriodSelector.tsx';

import { TimeSheet } from './TimeSheet';
import { TimeSheetHeader } from './TimeSheetHeader';

export const TimeTracker = () => {
  return (
    <>
      <BetaBar />
      <TimeSheetHeader />
      <TimeSheet />
      <footer className="fixed bottom-0 w-full border-t bg-navigation-transparent/75 text-navigation-foreground backdrop-blur sm:hidden">
        <PayPeriodSelector className="mx-auto" />
      </footer>
    </>
  );
};
