import { useAtom, useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';

import { TimeSheetEntryAddButton } from '@/components/TimeSheetEntryAddButton.tsx';
import { TimeSheetEntryCard } from '@/components/TimeSheetEntryCard.tsx';
import {
  Accordion,
  AccordionContent,
  AccordionHeader,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion.tsx';
import { globalDayCardStateAtom, hideEmptyDaysAtom } from '@/config/jotai.ts';
import { cn } from '@/lib/utils';
import { Project } from '@/types/Project';
import { TimeSheetEntry } from '@/types/TimeSheetEntry';
import { isToday, isWeekend } from '@/utility/dateUtils';

import { TimeSheetProgressBar } from './TimeSheetProgressBar';
import { Badge } from './ui/badge';

type Props = {
  entryDate: Date;
  isHoliday: boolean;
  projects: Project[];
  timeSheetEntries: TimeSheetEntry[];
};

export const TimeSheetDayCard = ({ entryDate, isHoliday, projects, timeSheetEntries }: Props) => {
  const [thisDayCardState, setThisDayCardState] = useState('open');
  const [globalDayCardState, setGlobalDayCardState] = useAtom(globalDayCardStateAtom);
  const hideEmptyDays = useAtomValue(hideEmptyDaysAtom);

  useEffect(() => {
    if (globalDayCardState) {
      setThisDayCardState(globalDayCardState);
    }
  }, [globalDayCardState]);

  const entryDateIsToday = isToday(entryDate);
  const formattedEntryDate = entryDate.toLocaleString('en-us', {
    day: 'numeric',
    month: 'numeric',
    weekday: 'long',
  });

  const totalHoursForDay = timeSheetEntries.reduce((result, entry) => {
    return result + entry.hours;
  }, 0);

  const onAccordionValueChange = (value: string): void => {
    if (globalDayCardState) {
      setGlobalDayCardState(null);
    }
    setThisDayCardState(value);
  };

  return hideEmptyDays && !timeSheetEntries.length && !entryDateIsToday ? null : (
    <Accordion
      type="single"
      collapsible
      className="w-full"
      defaultValue="open"
      value={timeSheetEntries.length ? thisDayCardState : 'closed'}
      disabled={!timeSheetEntries.length}
      onValueChange={(value) => onAccordionValueChange(value)}
    >
      <AccordionItem value="open" className="border-b-0">
        <AccordionHeader
          className={cn(
            'sticky top-[5.5rem] bg-list/95 text-list-foreground backdrop-blur sm:top-28',
            isWeekend(entryDate) && 'opacity-75'
          )}
        >
          <TimeSheetEntryAddButton
            className="my-1.5 ml-5 mr-7 hidden h-7 bg-primary/95 sm:flex"
            defaultDate={entryDate}
            projects={projects}
          ></TimeSheetEntryAddButton>
          <AccordionTrigger className="sm:mr-2">
            <div>
              {formattedEntryDate}
              {isHoliday && (
                <Badge className="ml-4 rounded-full bg-purple pb-px pt-0 hover:bg-purple sm:py-0.5">
                  Holiday
                </Badge>
              )}
              {entryDateIsToday && (
                <Badge className="ml-4 rounded-full bg-orange pb-px pt-0 hover:bg-orange sm:py-0.5">
                  Today
                </Badge>
              )}
            </div>
            {!!timeSheetEntries.length && (
              <div className="ml-auto mr-3">
                <div className="flex">
                  <div className="mr-4">{totalHoursForDay.toFixed(1)} hrs</div>
                  <div className="xsonly:hidden">
                    <TimeSheetProgressBar
                      className="inline-block h-[10px]"
                      timeSheetEntries={timeSheetEntries}
                      totalHours={totalHoursForDay}
                    />
                  </div>
                </div>
              </div>
            )}
          </AccordionTrigger>
        </AccordionHeader>
        <AccordionContent className="px-4 pb-2 sm:items-start sm:px-0">
          <div className="sm:flex sm:flex-wrap">
            {timeSheetEntries.map((timeSheetEntry) => (
              <TimeSheetEntryCard
                key={timeSheetEntry.id}
                timeSheetEntry={timeSheetEntry}
                projects={projects}
                className="mt-2 sm:mx-1 sm:mb-0 sm:w-[300px] sm:flex-none"
              />
            ))}
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
