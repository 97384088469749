import { useMutation, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { useEffect } from 'react';

import { getTimeSheetPayload } from '@/api/getTimeSheetPayload';
import { submitTimeSheet, SubmitTimeSheetProps } from '@/api/submitTimeSheet.ts';
import { ImportOutlookCalendarEvents } from '@/components/ImportOutlookCalendarEvents';
import { Menu } from '@/components/Menu.tsx';
import { PayPeriodSelector } from '@/components/PayPeriodSelector.tsx';
import { TimeSheetProgressBar } from '@/components/TimeSheetProgressBar';
import { Button } from '@/components/ui/button.tsx';
import CalendarPlus from '@/components/ui/CalendarPlus';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { toast } from '@/components/ui/use-toast.ts';
import {
  payPeriodAtom,
  payPeriodHasTimeEntriesAtom,
  requestedPayPeriodAtom,
  someTimeEntriesAreOpenAtom,
  totalHoursPerDayAtom,
  userIsW2Atom,
} from '@/config/jotai';
import { QueryKeys } from '@/lib/utils';
import { TimeSheetPayload } from '@/types/TimeSheetPayload';

export const TimeSheetHeader = () => {
  const totalHoursPerDay = useAtomValue(totalHoursPerDayAtom);
  const payPeriod = useAtomValue(payPeriodAtom);
  const payPeriodHasTimeEntries = useAtomValue(payPeriodHasTimeEntriesAtom);
  const someTimeEntriesAreOpen = useAtomValue(someTimeEntriesAreOpenAtom);
  const userIsW2 = useAtomValue(userIsW2Atom);
  const requestedPayPeriod = useAtomValue(requestedPayPeriodAtom);

  const { data: payloadData }: UseQueryResult<TimeSheetPayload> = useQuery({
    queryFn: () => getTimeSheetPayload({ dateInPayPeriod: requestedPayPeriod.beginDate }),
    queryKey: [QueryKeys.TimeSheetPayload, requestedPayPeriod],
  });

  const totalHoursEntered = [...totalHoursPerDay.values()].reduce(
    (acc, hoursForDay) => acc + hoursForDay,
    0
  );

  const totalPayPeriodHours = payPeriod?.totalHours ?? 0;

  const queryClient = useQueryClient();

  const { mutate: submitTimeSheetMutate, isPending: submitIsPending } = useMutation({
    mutationFn: ({ dateInPayPeriod }: SubmitTimeSheetProps) => {
      return submitTimeSheet({
        dateInPayPeriod,
      });
    },
    onError: (error) => {
      toast({
        description: error.message,
        title: error.name,
        variant: 'destructive',
      });
    },
    onSuccess: () => {
      toast({
        description: `Your time sheet has been submitted.`,
        title: 'Submit Time Sheet',
      });
      // Refresh the time sheet
      queryClient.invalidateQueries({ queryKey: [QueryKeys.TimeSheetPayload] });
    },
  });

  const timesheetHasBeenSubmitted =
    payPeriod?.isClosed || (payPeriodHasTimeEntries && !someTimeEntriesAreOpen);

  const submitButtonText = timesheetHasBeenSubmitted ? 'Submitted' : 'Submit';

  const enteredHoursText = timesheetHasBeenSubmitted
    ? `Submitted ${totalHoursEntered.toFixed(1)} hrs`
    : `Entered ${totalHoursEntered.toFixed(1)} of ${totalPayPeriodHours.toFixed(1)} hrs`;

  const canSubmitTimeSheet =
    !submitIsPending &&
    !!payPeriod &&
    !payPeriod.isClosed &&
    someTimeEntriesAreOpen &&
    (!userIsW2 || totalHoursEntered >= totalPayPeriodHours);

  // Add logging around how canSubmitTimeSheet is calculated
  useEffect(() => {
    console.groupCollapsed('🔍 Evaluating whether user can submit timesheet');

    console.log('submitIsPending:', submitIsPending ? '✅' : '❌');
    console.log('payPeriod.isClosed:', payPeriod?.isClosed ? '✅' : '❌');
    console.log('someTimeEntriesAreOpen:', someTimeEntriesAreOpen ? '✅' : '❌');
    console.log('userIsW2:', userIsW2 ? '✅' : '❌');
    console.log('totalHoursEntered:', totalHoursEntered);
    console.log('totalPayPeriodHours:', totalPayPeriodHours);
    console.log('%ccanSubmitTimeSheet:', 'font-weight: bold', canSubmitTimeSheet ? '✅' : '❌');

    console.groupEnd();
  }, [
    submitIsPending,
    payPeriod?.isClosed,
    someTimeEntriesAreOpen,
    userIsW2,
    totalHoursEntered,
    totalPayPeriodHours,
    canSubmitTimeSheet,
  ]);

  const timesheetEntries = payloadData?.timeSheet ?? [];
  const hoursForProgressBar = Math.max(totalHoursEntered, totalPayPeriodHours);

  return (
    <header className="sticky top-0 z-50 w-full bg-navigation text-navigation-foreground backdrop-blur supports-[backdrop-filter]:bg-navigation-transparent/75">
      <div className="bg-navigation/95 p-2.5">
        <h1 className="mb-1 scroll-m-20 text-center text-xl font-medium tracking-tight sm:text-4xl sm:font-normal">
          Time Sheet
        </h1>
        <div className="absolute right-4 top-3 flex">
          <ImportOutlookCalendarEvents className="hidden pr-3 sm:inline-flex">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <CalendarPlus />
                </TooltipTrigger>
                <TooltipContent>
                  <p>Import events from your Outlook calendar</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </ImportOutlookCalendarEvents>
          {/*To do use Alert Dialog */}
          <div hidden={timesheetHasBeenSubmitted} className="sm:pr-3">
            <Button
              variant="outline"
              className="hidden text-lg font-semibold sm:inline-flex"
              disabled={!canSubmitTimeSheet}
              onClick={() =>
                submitTimeSheetMutate({
                  dateInPayPeriod: new Date(payPeriod!.beginDate),
                })
              }
            >
              {submitButtonText}
            </Button>
          </div>
          <Menu
            canSubmitTimeSheet={canSubmitTimeSheet}
            submitButtonText={submitButtonText}
            submitIsPending={submitIsPending}
            submitTimeSheetMutate={submitTimeSheetMutate}
            className="hidden sm:inline-flex"
          />
        </div>
      </div>
      <div className="flex items-center justify-between">
        <PayPeriodSelector className="hidden px-6 sm:flex" />
        <div className="py-1 pl-4 pr-6 text-sm sm:py-0 sm:text-lg">
          {enteredHoursText}
          <TimeSheetProgressBar
            className="ml-4 hidden h-[10px] sm:hidden md:inline-flex"
            timeSheetEntries={timesheetEntries}
            totalHours={hoursForProgressBar}
          />
        </div>
        <Menu
          canSubmitTimeSheet={canSubmitTimeSheet}
          submitButtonText={submitButtonText}
          submitIsPending={submitIsPending}
          submitTimeSheetMutate={submitTimeSheetMutate}
          className="my-1 mr-4 h-7 w-7 sm:hidden"
        />
      </div>
      <TimeSheetProgressBar
        className="h-0.5 w-full md:hidden"
        timeSheetEntries={timesheetEntries}
        totalHours={hoursForProgressBar}
      />
    </header>
  );
};
