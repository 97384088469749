import { TimeSheetEntry, TimeSheetEntryStatus } from '@/types/TimeSheetEntry';

import { queryApi } from '../utility/queryApi';

export type EditTimeSheetEntryProps = {
  categoryId: string | null | undefined;
  description: string;
  entryDate: Date;
  hours: string;
  isNoVendorCharge: boolean | undefined;
  statusCode: TimeSheetEntryStatus | undefined;
  stintId: string;
  taskId: string;
  timeSheetEntryId: string | undefined;
};

export const editTimeSheetEntry = ({
  categoryId,
  description,
  entryDate,
  hours,
  isNoVendorCharge,
  statusCode,
  stintId,
  taskId,
  timeSheetEntryId,
}: EditTimeSheetEntryProps) =>
  queryApi<TimeSheetEntry>({
    body: JSON.stringify({
      categoryId: categoryId,
      description: description,
      entryDate: entryDate,
      hours: hours,
      id: timeSheetEntryId,
      isNoVendorCharge: isNoVendorCharge,
      statusCode: statusCode,
      stintId: stintId,
      taskId: taskId,
    }),
    method: 'PUT',
    path: `/api/time-sheet/entries/${timeSheetEntryId}`,
  });
