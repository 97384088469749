import { useMutation, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { HTMLAttributes, useEffect, useState } from 'react';

import { addTimeSheetEntriesv2, AddTimeSheetEntriesv2Props } from '@/api/addTimeSheetEntriesv2';
import { getCalendarEvents } from '@/api/getCalendarDayEvents';
import { getTimeSheetPayload } from '@/api/getTimeSheetPayload';
import { Button } from '@/components/ui/button.tsx';
import { toast } from '@/components/ui/use-toast.ts';
import {
  calendarEventsSubmittedAtom,
  isDataLoadingAtom,
  requestedPayPeriodAtom,
} from '@/config/jotai';
import { cn, QueryKeys } from '@/lib/utils';
import { CalendarEvent } from '@/types/CalendarEvent';
import { GraphCalendarEvents } from '@/types/GraphCalendarEvents';
import { TimeSheetEntry } from '@/types/TimeSheetEntry';
import { TimeSheetPayload } from '@/types/TimeSheetPayload';
import { getDatesInRange } from '@/utility/dateUtils';

type ImportOutlookCalendarEventsProps = {
  className?: string;
  children: React.ReactNode;
};

export const ImportOutlookCalendarEvents = ({
  className,
  children,
}: ImportOutlookCalendarEventsProps & HTMLAttributes<HTMLButtonElement>) => {
  const [isCalendarEventsSubmitted, setIsCalendarEventsSubmitted] = useAtom(
    calendarEventsSubmittedAtom
  );
  const requestedPayPeriod = useAtomValue(requestedPayPeriodAtom);
  const [hasCalendarEvents, setHasCalendarEvents] = useState(false);
  const [calendarEvents, setCalendarEvents] = useState<CalendarEvent[]>([]);
  const [stintId, setStintId] = useState<string | undefined>('');
  const [categoryId, setCategoryId] = useState<string | undefined>('');
  const [taskId, setTaskId] = useState<string | undefined>('');
  const setIsDataLoadingAtom = useSetAtom(isDataLoadingAtom);

  const { data: graphCalendarEvents }: UseQueryResult<GraphCalendarEvents> = useQuery({
    queryFn: () =>
      getCalendarEvents({
        eventEndDate: requestedPayPeriod.endDate,
        eventStartDate: requestedPayPeriod.beginDate,
      }),
    queryKey: [QueryKeys.CalendarEvents, requestedPayPeriod],
  });

  const { data: payloadData }: UseQueryResult<TimeSheetPayload> = useQuery({
    queryFn: () => getTimeSheetPayload({ dateInPayPeriod: requestedPayPeriod.beginDate }),
    queryKey: [QueryKeys.TimeSheetPayload, requestedPayPeriod],
  });

  //TODO: CHECK IF THIS CAN USE ISO STRING
  const getEstTimeStringFromUtcDate = (utcDateString: string): string => {
    // Create a Date object from the UTC string
    const utcDate = new Date(utcDateString);

    // Get the UTC time in milliseconds
    const utcTime = utcDate.getTime();

    // Define the offset for EST (in minutes)
    const estOffset = -4 * 60 * 60 * 1000; // EST is UTC-5

    // Adjust the UTC time by the EST offset
    const estTime = utcTime + estOffset;

    // Create a new Date object using the adjusted time
    const estDate = new Date(estTime);

    //return estDate.toLocaleString('en-US', { timeZone: 'America/New_York' });
    return estDate.toLocaleTimeString('en-US', {
      hour: 'numeric',
      hour12: true,
      minute: 'numeric',
      second: 'numeric',
    });
  };

  const queryClient = useQueryClient();

  useEffect(() => {
    if (graphCalendarEvents) {
      setHasCalendarEvents(true);

      //Get normal calendar events
      const calendarEvents: CalendarEvent[] = graphCalendarEvents.value
        .filter((x) => !x.isAllDay)
        .map((event) => {
          return {
            bodyPreview: event.bodyPreview,
            date: event.start.dateTime.split('T')[0],
            hours: event.isAllDay
              ? 8
              : (new Date(event.end.dateTime).getTime() -
                  new Date(event.start.dateTime).getTime()) /
                (1000 * 60 * 60),
            iCalUId: event.iCalUId,
            isSelected: false,
            subject: `[Imported from Outlook, update before submitting]\n${event.subject} from ${getEstTimeStringFromUtcDate(event.start.dateTime)} to ${getEstTimeStringFromUtcDate(
              event.end.dateTime
            )}`,
          };
        });

      //Get allday calendar events
      const allDayCalendarEvents = graphCalendarEvents.value.filter((x) => x.isAllDay);

      allDayCalendarEvents.forEach((event) => {
        const calendarDates = getDatesInRange(
          new Date(event.start.dateTime),
          new Date(new Date(event.end.dateTime).getTime() - 1000)
        );

        calendarDates.forEach((date) => {
          calendarEvents.push({
            bodyPreview: event.bodyPreview,
            date: date.toISOString().split('T')[0],
            hours: 8,
            iCalUId: event.iCalUId,
            isSelected: false,
            subject: `[Imported from Outlook, update before submitting]\n${event.subject}. All day event.`,
          });
        });
      });

      setCalendarEvents(calendarEvents);
    }
    if (payloadData) {
      const project = payloadData.submittableProjects.find(
        (p) => p.name === import.meta.env.VITE_CALENDAR_PROJECT
      );
      setStintId(project?.stintId);
      const category = project?.categories.find(
        (c) => c.name === import.meta.env.VITE_CALENDAR_CATEGORY
      );
      setCategoryId(category?.id);
      const task = project?.tasks.find((t) => t.name === import.meta.env.VITE_CALENDAR_TASK);
      setTaskId(task?.id);
    }
  }, [graphCalendarEvents, payloadData]);

  const { mutate: addTimeSheetEntriesv2Mutate, isPending: calendarEventsSubmitIsPending } =
    useMutation({
      mutationFn: ({ timesheetEntries }: AddTimeSheetEntriesv2Props) => {
        setIsDataLoadingAtom(true);
        return addTimeSheetEntriesv2({
          timesheetEntries,
        });
      },
      onError: (error) => {
        toast({
          description: error.message,
          title: error.name,
          variant: 'destructive',
        });
      },
      onSuccess: () => {
        toast({
          description: 'Successfully added calendar time sheet entries',
          title: 'Calendar Time Sheet Entries',
        });
        setHasCalendarEvents(false);
        setIsCalendarEventsSubmitted(true);
        setIsDataLoadingAtom(false);
        // Refresh the list of time sheet entries
        queryClient.invalidateQueries({ queryKey: [QueryKeys.TimeSheetPayload] });
      },
    });

  const addCalendarTimeSheetEntries = (calendarEvents: CalendarEvent[]) => {
    const timesheetEntries: TimeSheetEntry[] = calendarEvents.map((calendarEvent) => ({
      categoryId: categoryId,
      description: calendarEvent.subject,
      entryDate: calendarEvent.date,
      hours: calendarEvent.hours,
      stintId: stintId!,
      taskId: taskId!,
    }));

    addTimeSheetEntriesv2Mutate({ timesheetEntries });
  };

  //Check if pay period is closed
  const canSubmitCalendarEvents =
    hasCalendarEvents && !calendarEventsSubmitIsPending && !isCalendarEventsSubmitted;

  return (
    <Button
      variant="link"
      disabled={!canSubmitCalendarEvents}
      className={cn('text-lg font-semibold text-primary-foreground', className)}
      onClick={(e) => {
        e.preventDefault();
        const confirmMessage = `Are you sure you want to import ${calendarEvents.length} events from your Outlook calendar?`;
        if (window.confirm(confirmMessage)) {
          addCalendarTimeSheetEntries(calendarEvents);
        }
      }}
    >
      {children}
    </Button>
  );
};
