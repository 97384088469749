import { queryApi } from '@/utility/queryApi';

export type UnsubmitTimeSheetProps = {
  dateInPayPeriod: Date;
};

export const unsubmitTimeSheet = ({ dateInPayPeriod }: UnsubmitTimeSheetProps) =>
  queryApi<undefined>({
    method: 'POST',
    path: `/api/time-sheet/unsubmit/${dateInPayPeriod.toISOString()}`,
  });
