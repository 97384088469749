import { useMsal } from '@azure/msal-react';
import { DotsVerticalIcon } from '@radix-ui/react-icons';
import { UseMutateFunction, useMutation, useQueryClient } from '@tanstack/react-query';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { HTMLAttributes } from 'react';

import { SubmitTimeSheetProps } from '@/api/submitTimeSheet.ts';
import { unsubmitTimeSheet, UnsubmitTimeSheetProps } from '@/api/unsubmitTimeSheet.ts';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { toast } from '@/components/ui/use-toast.ts';
import {
  globalDayCardStateAtom,
  hideEmptyDaysAtom,
  payPeriodAtom,
  someTimeEntriesAreSubmittedAtom,
} from '@/config/jotai.ts';
import { useTheme } from '@/hooks/useTheme';
import { cn, QueryKeys } from '@/lib/utils';

import { ImportOutlookCalendarEvents } from './ImportOutlookCalendarEvents';

type MenuProps = {
  canSubmitTimeSheet: boolean;
  submitButtonText: string;
  submitIsPending: boolean;
  submitTimeSheetMutate: UseMutateFunction<undefined, Error, SubmitTimeSheetProps, unknown>;
};

export function Menu({
  canSubmitTimeSheet,
  submitButtonText,
  submitIsPending,
  submitTimeSheetMutate,
  className,
}: MenuProps & HTMLAttributes<HTMLButtonElement>) {
  const payPeriod = useAtomValue(payPeriodAtom);
  const { setTheme, theme } = useTheme();
  const setGlobalDayCardState = useSetAtom(globalDayCardStateAtom);
  const someTimeEntriesAreSubmitted = useAtomValue(someTimeEntriesAreSubmittedAtom);
  const [hideEmptyDays, setHideEmptyDays] = useAtom(hideEmptyDaysAtom);
  const { instance: msalInstance } = useMsal();

  const queryClient = useQueryClient();

  const { mutate: unsubmitTimeSheetMutate, isPending: unsubmitIsPending } = useMutation({
    mutationFn: ({ dateInPayPeriod }: UnsubmitTimeSheetProps) =>
      unsubmitTimeSheet({
        dateInPayPeriod,
      }),
    onError: (error) => {
      toast({
        description: error.message,
        title: error.name,
        variant: 'destructive',
      });
    },
    onSuccess: () => {
      toast({
        description: `Your time sheet submission has been undone.`,
        title: 'Undo Submit Time Sheet',
      });

      // Refresh the time sheet
      queryClient.invalidateQueries({ queryKey: [QueryKeys.TimeSheetPayload] });
    },
  });

  const canUnsubmitTimeSheet =
    !submitIsPending &&
    !unsubmitIsPending &&
    payPeriod &&
    !payPeriod.isClosed &&
    someTimeEntriesAreSubmitted;

  const logout = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    event.preventDefault();
    msalInstance.logoutRedirect().catch((e) => {
      console.error(e);
    });
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" size="icon" className={className}>
          <DotsVerticalIcon />
          <span className="sr-only">Menu</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem
          className="font-semibold sm:hidden"
          disabled={!canSubmitTimeSheet}
          onClick={() =>
            submitTimeSheetMutate({
              dateInPayPeriod: new Date(payPeriod!.beginDate),
            })
          }
        >
          {submitButtonText}
        </DropdownMenuItem>
        <DropdownMenuItem
          disabled={!canUnsubmitTimeSheet}
          onClick={() =>
            unsubmitTimeSheetMutate({
              dateInPayPeriod: new Date(payPeriod!.beginDate),
            })
          }
        >
          Undo Submit
        </DropdownMenuItem>
        <ImportOutlookCalendarEvents className="p-0 text-sm font-normal text-popover-foreground sm:hidden">
          <DropdownMenuItem>Import Outlook</DropdownMenuItem>
        </ImportOutlookCalendarEvents>
        <DropdownMenuSeparator />
        <DropdownMenuItem
          className={cn('sm:hidden', hideEmptyDays && 'bg-accent')}
          onClick={() => setHideEmptyDays(!hideEmptyDays)}
        >
          Hide Empty Days
        </DropdownMenuItem>
        <DropdownMenuItem className="sm:hidden" onClick={() => setGlobalDayCardState('open')}>
          Expand All
        </DropdownMenuItem>
        <DropdownMenuItem className="sm:hidden" onClick={() => setGlobalDayCardState('closed')}>
          Collapse All
        </DropdownMenuItem>
        <DropdownMenuSeparator className="sm:hidden" />
        <DropdownMenuItem
          className={cn(theme === 'light' && 'bg-accent')}
          onClick={() => setTheme('light')}
        >
          Light
        </DropdownMenuItem>
        <DropdownMenuItem
          className={cn(theme === 'dark' && 'bg-accent')}
          onClick={() => setTheme('dark')}
        >
          Dark
        </DropdownMenuItem>
        <DropdownMenuItem
          className={cn(theme === 'system' && 'bg-accent')}
          onClick={() => setTheme('system')}
        >
          System
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={(e) => logout(e)}>Logout</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
