import { PlusIcon } from '@radix-ui/react-icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { SubmitHandler } from 'react-hook-form';

import { addTimeSheetEntries, AddTimeSheetEntriesProps } from '@/api/addTimeSheetEntries';
import { TimeSheetEntryAddForm } from '@/components/TimeSheetEntryAddForm.tsx';
import { QueryKeys } from '@/lib/utils';
import { Project } from '@/types/Project';
import { TimeSheetEntryAddValues } from '@/types/TimeSheetEntryFormValues';

import { Icons } from './icons';
import { Button } from './ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from './ui/dialog';
import { useToast } from './ui/use-toast';

type Props = {
  defaultDate?: Date;
  projects: Project[];
};

export const TimeSheetEntryAddButton = ({
  defaultDate,
  projects,
  className,
}: Props & React.HTMLAttributes<SVGSVGElement>) => {
  const [showAddTimeSheetEntryDialog, setShowAddTimeSheetEntryDialog] = useState(false);
  const { toast } = useToast();

  const queryClient = useQueryClient();

  const { mutate: addTimeSheetEntryMutate, isPending: addTimeSheetEntryPending } = useMutation({
    mutationFn: ({ categoryId, days, description, stintId, taskId }: AddTimeSheetEntriesProps) =>
      addTimeSheetEntries({
        categoryId,
        days,
        description,
        stintId,
        taskId,
      }),
    onError: (error) => {
      toast({
        description: error.message,
        title: error.name,
        variant: 'destructive',
      });
    },
    onSuccess: () => {
      toast({
        description: 'Successfully added time sheet entry',
        title: 'Add Time Sheet Entry',
      });
      // Refresh the list of time sheet entries
      queryClient.invalidateQueries({ queryKey: [QueryKeys.TimeSheetPayload] });
      setShowAddTimeSheetEntryDialog(false);
    },
  });

  const handleSubmit: SubmitHandler<TimeSheetEntryAddValues> = (data) => {
    addTimeSheetEntryMutate({
      categoryId: data.category,
      days: data.dates.map((date) => {
        return {
          entryDate: date,
          hours: data.hours.toFixed(1),
        };
      }),
      description: data.description,
      stintId: data.project,
      taskId: data.task ?? '',
    });
  };

  const defaultValues: Partial<TimeSheetEntryAddValues> = {
    dates: defaultDate ? [defaultDate] : undefined,
  };

  return (
    <Dialog open={showAddTimeSheetEntryDialog} onOpenChange={setShowAddTimeSheetEntryDialog}>
      <DialogTrigger asChild>
        <Button className={className} size="icon">
          <PlusIcon />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add Time Sheet Entry</DialogTitle>
          <DialogDescription>Add a new time sheet entry</DialogDescription>
        </DialogHeader>
        <TimeSheetEntryAddForm
          defaultValues={defaultValues}
          projects={projects}
          handleSubmit={handleSubmit}
          submitPending={addTimeSheetEntryPending}
        />
        <DialogFooter>
          <div className="flex flex-col-reverse gap-3 sm:flex-row sm:justify-between ">
            <Button
              variant="outline"
              disabled={addTimeSheetEntryPending}
              onClick={() => setShowAddTimeSheetEntryDialog(false)}
            >
              Cancel
            </Button>
            <Button form="time-log-modal-submit" type="submit" disabled={addTimeSheetEntryPending}>
              {addTimeSheetEntryPending ? (
                <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
              ) : (
                ''
              )}{' '}
              Submit
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
