import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Project } from '@/types/Project';
import { TimeSheetEntryFormValues } from '@/types/TimeSheetEntryFormValues';

import ScrollableContainer from './ScrollableContainer';
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from './ui/form';
import { Input } from './ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from './ui/select';
import { Textarea } from './ui/textarea';

type TimeSheetEntryFormProps = {
  calendarFormField: React.ReactElement;
  defaultValues: Partial<TimeSheetEntryFormValues>;
  projects: Project[];
  submitPending: boolean;
};

export const TimeSheetEntryForm = ({
  calendarFormField,
  defaultValues,
  projects,
  submitPending,
}: TimeSheetEntryFormProps) => {
  const [selectedStintId, setSelectedStintId] = useState(defaultValues.project);
  const { control, resetField } = useFormContext<TimeSheetEntryFormValues>();

  return (
    <>
      <div className="grid grid-cols-2 items-center gap-3">
        {calendarFormField}
        <FormField
          control={control}
          name="hours"
          disabled={submitPending}
          render={({ field }) => (
            <FormItem className="flex flex-col">
              <FormLabel htmlFor="number">Hours</FormLabel>
              <FormControl>
                <Input disabled={submitPending} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>

      {/* Watch this PR to fix SelectValue and possibly add loading state that way https://github.com/shadcn-ui/ui/issues/1529 */}
      <FormField
        control={control}
        name="project"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Project</FormLabel>
            <Select
              onValueChange={(value) => {
                setSelectedStintId(value);
                resetField('task', { defaultValue: null });
                resetField('category', { defaultValue: null });
                return field.onChange(value);
              }}
              value={field.value ?? ''}
              disabled={submitPending}
            >
              <FormControl>
                <SelectTrigger>
                  <SelectValue placeholder="Select a project" />
                </SelectTrigger>
              </FormControl>
              <SelectContent>
                {/* ScrollableContainer is possibly not needed when Radix fixes their Select https://github.com/radix-ui/primitives/issues/1980 */}
                <ScrollableContainer>
                  {projects.map((project) => (
                    <SelectItem key={project.name} value={project.stintId}>
                      {project.name}
                    </SelectItem>
                  ))}
                </ScrollableContainer>
              </SelectContent>
            </Select>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={control}
        name="task"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Task</FormLabel>
            <Select
              onValueChange={field.onChange}
              value={field.value ?? ''}
              disabled={submitPending}
            >
              <FormControl>
                <SelectTrigger>
                  <SelectValue placeholder={submitPending ? 'Loading...' : 'Select a task'} />
                </SelectTrigger>
              </FormControl>
              <SelectContent>
                <ScrollableContainer>
                  {projects
                    .find((p) => p.stintId === selectedStintId)
                    ?.tasks.sort((a, b) => (a.displayName > b.displayName ? 1 : -1))
                    .map((task) => (
                      <SelectItem key={task.displayName} value={task.id}>
                        {task.displayName}
                      </SelectItem>
                    ))}
                </ScrollableContainer>
              </SelectContent>
            </Select>
            <FormMessage />
          </FormItem>
        )}
      />

      {!!projects.find((p) => p.stintId === selectedStintId)?.categories.length && (
        <FormField
          control={control}
          name="category"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Category</FormLabel>
              <Select
                onValueChange={field.onChange}
                value={field.value ?? ''}
                disabled={submitPending}
              >
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select a category" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <ScrollableContainer>
                    {projects
                      .find((p) => p.stintId === selectedStintId)
                      ?.categories.map((category) => (
                        <SelectItem key={category.name} value={category.id}>
                          {category.name}
                        </SelectItem>
                      ))}
                  </ScrollableContainer>
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
      )}

      <FormField
        control={control}
        name="description"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Description</FormLabel>
            <FormControl>
              <Textarea
                placeholder="Write out the description of the time sheet entry"
                className="resize-y"
                disabled={submitPending}
                {...field}
              />
            </FormControl>
            {(!field.value?.length || field.value.length <= 300) && (
              <FormDescription>
                {300 - (field.value?.length ?? 0)} characters remaining
              </FormDescription>
            )}
            <FormMessage />
          </FormItem>
        )}
      />
    </>
  );
};
