import { useAtomValue } from 'jotai';

import { projectIdColorsAtom, userIsW2Atom } from '@/config/jotai';
import { cn } from '@/lib/utils';
import { ProjectIdColor } from '@/types/ProjectIdColor';
import { TimeSheetEntry } from '@/types/TimeSheetEntry';

type Props = {
  className?: string;
  timeSheetEntries: TimeSheetEntry[];
  totalHours: number;
};

const build1099BarSection = (loggedHours: number, totalHours: number) => {
  const width = 100 * (loggedHours / totalHours);

  return (
    <div
      className="absolute left-0 inline h-full rounded-full bg-green-400"
      style={{ width: `${width}%` }}
    ></div>
  );
};

const buildW2BarSections = (
  projectIdColors: ProjectIdColor[],
  timeSheetEntries: TimeSheetEntry[],
  totalHours: number
) => {
  let currentWidth = 0;

  const groupedMap = timeSheetEntries.reduce(
    (entryMap, e) => entryMap.set(e.projectId, [...(entryMap.get(e.projectId) || []), e]),
    new Map()
  );

  let groupedMapIndex = 0;
  const bars: JSX.Element[] = [];

  groupedMap.forEach((timeSheetEntriesForGroup: TimeSheetEntry[]) => {
    const hoursForGrouping = timeSheetEntriesForGroup.reduce((result, entry) => {
      return result + entry.hours;
    }, 0);

    const width = 100 * (totalHours < 8 ? hoursForGrouping / 8 : hoursForGrouping / totalHours);

    const color = projectIdColors.find(
      (pic) => pic.projectId === timeSheetEntriesForGroup[0].projectId
    )?.color;

    const style = {
      backgroundColor: color,
      left: `${currentWidth}%`,
      width: `${width}%`,
    };

    currentWidth += width;

    const classValues: string[] = ['absolute h-full inline'];

    // Round the corners of the beginning and end sections
    if (groupedMapIndex === 0) {
      classValues.push('rounded-l-full');
    }

    if (groupedMapIndex === groupedMap.size - 1) {
      classValues.push('rounded-r-full');
    }

    const hoursForGroupingLabel = hoursForGrouping === 1 ? 'hr' : 'hrs';
    const barTitle = `${timeSheetEntriesForGroup[0].project?.displayName} - ${hoursForGrouping} ${hoursForGroupingLabel}`;

    bars.push(
      <div key={groupedMapIndex} className={cn(classValues)} style={style} title={barTitle}></div>
    );

    groupedMapIndex++;
  });

  return bars;
};

export const TimeSheetProgressBar = ({ className, timeSheetEntries, totalHours }: Props) => {
  const userIsW2 = useAtomValue(userIsW2Atom);
  const projectIdColors = useAtomValue(projectIdColorsAtom);

  let barSections: JSX.Element[];

  if (userIsW2) {
    const w2BarSections = buildW2BarSections(projectIdColors ?? [], timeSheetEntries, totalHours);

    barSections = w2BarSections;
  } else {
    const loggedHours = timeSheetEntries.reduce((result, entry) => {
      return result + entry.hours;
    }, 0);

    const barSection = build1099BarSection(loggedHours, totalHours);

    barSections = [barSection];
  }

  return (
    <div className={cn('relative w-[200px] rounded-full bg-gray-500', className)}>
      {barSections}
    </div>
  );
};
