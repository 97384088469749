import { zodResolver } from '@hookform/resolvers/zod';
import { CalendarIcon } from '@radix-ui/react-icons';
import { useAtomValue } from 'jotai';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { TimeSheetEntryForm } from '@/components/TimeSheetEntryForm.tsx';
import { requestedPayPeriodAtom, totalHoursPerDayAtom } from '@/config/jotai';
import { cn } from '@/lib/utils';
import { Project } from '@/types/Project';
import {
  setCategoryIsValid,
  timeSheetEntryAddSchema,
  TimeSheetEntryAddValues,
} from '@/types/TimeSheetEntryFormValues';

import { Button } from './ui/button';
import { Calendar } from './ui/calendar';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from './ui/form';
import { Popover, PopoverContent, PopoverTrigger } from './ui/popover';

type TimeSheetEntryAddFormProps = {
  defaultValues: Partial<TimeSheetEntryAddValues>;
  projects: Project[];
  handleSubmit: SubmitHandler<TimeSheetEntryAddValues>;
  submitPending: boolean;
};

export const TimeSheetEntryAddForm = ({
  defaultValues,
  projects,
  handleSubmit,
  submitPending,
}: TimeSheetEntryAddFormProps) => {
  const [open, setOpen] = useState(false);
  const requestedPayPeriod = useAtomValue(requestedPayPeriodAtom);
  const totalHoursPerDay = useAtomValue(totalHoursPerDayAtom);

  const form = useForm<TimeSheetEntryAddValues>({
    defaultValues,
    mode: 'onChange',
    resolver: zodResolver(timeSheetEntryAddSchema),
  });

  const willExceed24Hours = (hours: number, date: Date) => {
    const currentHoursForDay = totalHoursPerDay.get(date.toDateString()) ?? 0;
    return currentHoursForDay + hours > 24;
  };

  // Submit the form when the user presses Ctrl + Enter
  const handleOnKeyDownCapture = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.ctrlKey && event.key === 'Enter') {
      event.preventDefault();
      form.handleSubmit(submitForm)();
    }
  };

  const submitForm = (data: TimeSheetEntryAddValues) => {
    const categoryIsValid = setCategoryIsValid(projects, data, form.clearErrors, form.setError);
    if (!categoryIsValid) {
      return;
    }

    form.clearErrors('hours');
    const dateWithOver24Hours = data.dates.find((date) => willExceed24Hours(data.hours, date));

    if (dateWithOver24Hours) {
      const date = dateWithOver24Hours.toLocaleDateString();
      form.setError('hours', {
        message: `The total number of hours for ${date} will exceed 24 hours`,
        type: 'manual',
      });
    } else {
      handleSubmit(data);
    }
  };

  function getCalendarDisplayValue(dates: Date[]): string {
    if (!dates) return '';

    const orderedDates = dates.sort((dateA, dateB) => dateA.getTime() - dateB.getTime());
    let dateDisplay = orderedDates[0].toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'short',
    });

    for (let i = 1; i < orderedDates.length; i++) {
      dateDisplay += `, ${orderedDates[i].getDate()}`;
    }

    return dateDisplay;
  }

  const calendarFormField = (
    <FormField
      control={form.control}
      name="dates"
      render={({ field }) => (
        <FormItem className="flex flex-col">
          <FormLabel>Date</FormLabel>
          <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant="outline"
                  className={cn(
                    'overflow-hidden pl-3 font-normal',
                    !field.value && 'text-muted-foreground'
                  )}
                  disabled={submitPending}
                >
                  <span>{getCalendarDisplayValue(field.value)}</span>
                  <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
              <Calendar
                mode="multiple"
                selected={field.value}
                onSelect={field.onChange}
                disabled={(date) =>
                  date > requestedPayPeriod.endDate || date < requestedPayPeriod.beginDate
                }
                defaultMonth={requestedPayPeriod.beginDate}
                min={1}
              />
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );

  return (
    <Form {...form}>
      {/*{field.value ? (*/}
      {/*  <span className="mr-auto overflow-hidden">*/}
      {/*    {getCalendarDisplayValue(field.value)}*/}
      {/*  </span>*/}
      {/*) : (*/}
      {/*  <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />*/}
      {/*)}*/}
      <form
        id="time-log-modal-submit"
        onSubmit={form.handleSubmit(submitForm)}
        className="flex w-full flex-col gap-3"
        onKeyDownCapture={handleOnKeyDownCapture}
      >
        <TimeSheetEntryForm
          calendarFormField={calendarFormField}
          defaultValues={defaultValues}
          projects={projects}
          submitPending={submitPending}
        />
      </form>
    </Form>
  );
};
