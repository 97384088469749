export const BetaBar = () => {
  const showBetaBar = import.meta.env.VITE_BETA_BAR_SHOW || true;

  if (showBetaBar === 'false') return;

  const dashboardClassicUrl =
    import.meta.env.VITE_BETA_BAR_CLASSIC_URL || `https://dashboarddev.e-gineering.com`;

  return (
    <div className="bg-yellow-400 py-3 text-center font-bold text-black">
      This site is in beta. Click{' '}
      <a className="underline" href={dashboardClassicUrl} target="_blank" rel="noreferrer">
        here
      </a>{' '}
      to access Dashboard Classic.
    </div>
  );
};
