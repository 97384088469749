import { TimeSheetEntry } from '@/types/TimeSheetEntry.ts';

import { queryApi } from '../utility/queryApi';

type Day = {
  entryDate: Date;
  hours: string;
};

export type AddTimeSheetEntriesProps = {
  categoryId: string | null | undefined;
  days: Day[];
  description: string;
  stintId: string;
  taskId: string;
};

export const addTimeSheetEntries = ({
  categoryId,
  days,
  description,
  stintId,
  taskId,
}: AddTimeSheetEntriesProps) =>
  queryApi<TimeSheetEntry[]>({
    body: JSON.stringify({
      categoryId: categoryId,
      days: days,
      description: description,
      isNoVendorCharge: false,
      stintId: stintId,
      taskId: taskId,
    }),
    method: 'POST',
    path: '/api/time-sheet/entries',
  });
