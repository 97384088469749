import { queryApi } from '@/utility/queryApi';

export type SubmitTimeSheetProps = {
  dateInPayPeriod: Date;
};

export const submitTimeSheet = ({ dateInPayPeriod }: SubmitTimeSheetProps) =>
  queryApi<undefined>({
    method: 'POST',
    path: `/api/time-sheet/submit/${dateInPayPeriod.toISOString()}`,
  });
